import {
  ResizeObserver as PResizeObserver,
  ResizeObserverEntry as PResizeObserverEntry,
  ResizeObserverSize as PResizeObserverSize,
} from '@juggle/resize-observer';

import { getFeatureQueryParamArray } from '../hooks/useFeatureQueryParam';

const native =
  getFeatureQueryParamArray('resize-observer') === 'native' &&
  typeof document !== 'undefined' &&
  typeof window !== 'undefined' &&
  typeof window.ResizeObserver !== 'undefined';

export const ResizeObserver = native ? window.ResizeObserver : PResizeObserver;
export const ResizeObserverEntry = native
  ? window.ResizeObserverEntry
  : PResizeObserverEntry;
export const ResizeObserverSize = native
  ? window.ResizeObserverSize
  : PResizeObserverSize;

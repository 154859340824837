import { useCallback } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { type GroupSettings, type PrivacySettings } from '../../types';

type UsePrivacySettings = {
  privacySettings: GroupSettings<PrivacySettings> | null | undefined;
  isLoading: boolean;
  error: Error | undefined;
  update: (
    privacySettings: Partial<PrivacySettings>
  ) => Promise<GroupSettings<PrivacySettings> | null>;
};

async function loadPrivacySettings(): Promise<GroupSettings<PrivacySettings> | null> {
  const resp = await apiService.userSettings.getMyPrivacySettings();
  return resp.data.privacy ?? null;
}

export function usePrivacySettings(): UsePrivacySettings {
  const { data, error, isValidating, mutate } = useSWR<
    GroupSettings<PrivacySettings> | null,
    Error
  >('/users/settings/privacy', loadPrivacySettings, {
    shouldRetryOnError: false,
  });

  const update = useCallback(
    async (privacySettings: Partial<PrivacySettings>) => {
      const resp = await apiService.userSettings.updateMyPrivacySettings(
        privacySettings
      );
      const privacy = resp.data.privacy ?? null;
      mutate(privacy);
      return privacy;
    },
    [mutate]
  );

  return {
    privacySettings: data,
    isLoading: isValidating,
    error,
    update,
  };
}
